import { Icon, Text, Tooltip } from 'components/ui';
import { format, getISOWeek } from 'date-fns';
import { WeeklyBookingReport } from 'lib/api/generated';
import { useRouter } from 'next/router';
import { FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export interface SummaryCardProps {
  name: string;
  details: WeeklyBookingReport[];
  productId: string;
}

export const CardWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-bottom: ${globalTheme.space[2]};
  padding: ${globalTheme.space[1]} ${globalTheme.space[1]};
  background-color: #f8f8f8;
  border-radius: 8px;
`;

export const RowInner = styled.div`
  text-align: center;
`;

export const RowWrapper = styled.div`
  padding: ${globalTheme.space[0]} ${globalTheme.space[1]};
  background-color: ${globalTheme.colors.white};
  border-radius: 8px;
`;

const SummaryBox = styled(RowWrapper)`
  background-color: #ffffff;
`;

const InOutCount = styled.div`
  display: flex;
  gap: ${globalTheme.space[1]};
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${globalTheme.space[0]};
`;

export const SummaryMobileCard: FC<SummaryCardProps> = ({
  name,
  details,
  productId,
}) => {
  const router = useRouter();

  const handleClickSummary = (startDate: Date, endDate: Date, type: string) => {
    router.push(`/stockbook?type=${type}&date=${startDate}&date_to=${endDate}`);
  };

  return (
    <>
      <CardWrapper>
        <Text fontSize={5}>{name}</Text>
        {details?.map((item: any, index: number) => {
          return (
            <>
              <SummaryBox
                className='dayRow'
                onClick={() =>
                  handleClickSummary?.(
                    item.start_date,
                    item.end_date,
                    productId
                  )
                }
              >
                <RowInner>
                  <Text fontSize={3} fontWeight='semiBold' as='span'>
                    Wk {getISOWeek(new Date(item?.end_date))} :{' '}
                    {format(new Date(item?.start_date), 'dd MMM')} -{' '}
                    {format(new Date(item?.end_date), 'dd MMM')}
                  </Text>
                </RowInner>

                <InOutCount>
                  <Tooltip label='Incoming'>
                    <IconWrapper>
                      <Icon size={5} color='blue' component='IncomingIcon' />
                      <Text color='blue' fontSize={4} fontWeight='semiBold'>
                        {item.incoming[productId]}
                      </Text>
                    </IconWrapper>
                  </Tooltip>
                  <Tooltip label='Outgoing'>
                    <IconWrapper>
                      <Icon size={5} color='pink' component='OutgoingIcon' />
                      <Text color='pink' fontSize={4} fontWeight='semiBold'>
                        {item.outgoing[productId]}
                      </Text>
                    </IconWrapper>
                  </Tooltip>
                </InOutCount>
              </SummaryBox>
            </>
          );
        })}
      </CardWrapper>
    </>
  );
};
