import {
  BasicTable,
  Button,
  Card,
  Cell,
  CellHeader,
  DividerItem,
  Grid,
  Row,
  Text,
  VisuallyHidden,
} from 'components/ui';
import { WeeklyBookingReport } from 'lib/api/generated';
import { useRouter } from 'next/router';
import { FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from '../../styles/global-theme';
import { format } from 'date-fns';

export interface SummaryCardProps {
  name: string;
  details?: WeeklyBookingReport[];
  productId: string;
}

const ActionsHeader = styled(CellHeader)`
  text-align: right;
  position: relative;
`;

const ButtonCell = styled(Cell)`
  padding-right: 40px;
`;

const ColorButton = styled(Button)(() => ({
  color: 'white',
  backgroundColor: '#C934E1',
  fontSize: globalTheme.fontSizes[3],
}));

const SummaryList = styled.div`
  max-height: 200px;
  overflow-y: scroll;
`;

const CellValue = styled(Cell)`
  font-size: ${globalTheme.fontSizes[3]};
`;

const LargeCellHeader = styled(CellHeader)`
  font-size: ${globalTheme.fontSizes[3]};
`;

export const SummaryCard: FC<SummaryCardProps> = ({
  name,
  details,
  productId,
}) => {
  const router = useRouter();

  const handleClickSummary = (startDate: Date, endDate: Date, type: string) => {
    router.push(`/stockbook?type=${type}&date=${startDate}&date_to=${endDate}`);
  };

  return (
    <Card>
      <DividerItem space={1}>
        <Grid columns='1fr max-content' gap={1} stackOnSmall>
          <Text fontSize={5}>{name}</Text>
        </Grid>
      </DividerItem>
      <BasicTable fixed={true} className='summary-booking'>
        <Row>
          <LargeCellHeader>Week Commencing</LargeCellHeader>
          <LargeCellHeader>Incoming</LargeCellHeader>
          <LargeCellHeader>Outgoing</LargeCellHeader>
          <ActionsHeader>
            <VisuallyHidden>Actions</VisuallyHidden>
          </ActionsHeader>
        </Row>
      </BasicTable>
      <SummaryList>
        <BasicTable fixed={true}>
          {details?.map((item: any, index: number) => {
            return (
              <Row key={index}>
                <CellValue>
                  {format(new Date(item.start_date), 'd MMM')}
                </CellValue>
                <CellValue>{item.incoming[productId]}</CellValue>
                <CellValue>{item.outgoing[productId]}</CellValue>
                <ButtonCell>
                  <ColorButton
                    size='xSmall'
                    color='pink'
                    onClick={() =>
                      handleClickSummary?.(
                        item.start_date,
                        item.end_date,
                        productId
                      )
                    }
                  >
                    View
                  </ColorButton>
                </ButtonCell>
              </Row>
            );
          })}
        </BasicTable>
      </SummaryList>
    </Card>
  );
};
