import { useAbility } from '@casl/react';
import { useQuery } from '@tanstack/react-query';
import { AbilityContext } from 'components/can';
import { Grid, Loader } from 'components/ui';
import { useBuyerOrg } from 'hooks/use-buyer-org';
import { ApiClient } from 'lib/api/client';
import { useMedia } from 'react-use';
import { breakpoints } from 'styles/breakpoints';
import { SummaryCard } from './summary-card';
import { SummaryMobileCard } from './summary-mobile-card';

export const BookingSummaryCard = () => {
  const isMobile = useMedia(`(max-width: ${breakpoints.medium})`);
  const { data: org } = useBuyerOrg();
  const ability = useAbility(AbilityContext);
  const canRead = ability.can('read', 'Stockbook');

  const { isLoading, data: activeFields } = useQuery(['activeFields'], () =>
    new ApiClient().organisationApi.orgConfigRetrieve({
      buyerOrgId: org?.buyer_org_id || '',
    })
  );

  const { isLoading: weeklyLoading, data: weeklyData } = useQuery(
    ['weeklyData'],
    () =>
      new ApiClient().reportsApi.orgReportBookingWeeklyList({
        buyerOrgId: org?.buyer_org_id || '',
      })
  );

  if (isLoading && weeklyLoading) {
    return <Loader />;
  }
  if (canRead) {
    return (
      <>
        {isMobile ? (
          <>
            {activeFields?.data?.products.map((product, index: number) => {
              return (
                <SummaryMobileCard
                  key={index}
                  name={`${product.name} booking summary` || ''}
                  details={weeklyData?.data as []}
                  productId={product.product_id}
                />
              );
            })}
          </>
        ) : (
          <Grid auto={{ min: '420px' }} gap={1}>
            {activeFields?.data?.products.map((product, index: number) => {
              return (
                <SummaryCard
                  key={index}
                  name={`${product.name} booking summary` || ''}
                  productId={product.product_id}
                  details={weeklyData?.data}
                />
              );
            })}
          </Grid>
        )}
      </>
    );
  } else {
    return null;
  }
};
