import { BookingSummaryCard } from 'components/booking-summary-card/booking-summary-card';
import { Layout } from 'components/layout';
import { IconProps, Loader, PageWrapper, Stack, Text } from 'components/ui';
import { withAuth } from 'components/with-auth';
import { NextPage } from 'next';
import { PATHS } from 'settings/config';
import styled from 'styled-components';
import { useBuyerOrg } from '../hooks/use-buyer-org';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../lib/api/client';
import React from 'react';
import StockbookRequiredSummary from './stockbook-required-summary';

type Feature = {
  title: string;
  description: string;
  buttonText: string;
  link: string;
  icon: IconProps['component'];
};

{
  /** TODO: disable for the moment. As the page is quite busy with the other features. [Mon 20 Jan] */
}
// const features: Feature[] = [
//   {
//     title: 'Set up your company profile',
//     description:
//       'Set up your company profile information, delivery addresses, and users. Manage your subscription plan.',
//     buttonText: 'Company Profile',
//     link: PATHS.organisation.profile,
//     icon: 'BusinessIcon',
//   },
//   {
//     title: 'Build your supplier list',
//     description:
//       'Search for suppliers (vendors and agents) on Agora Livestock, or send them an invite to join.',
//     buttonText: 'Suppliers',
//     link: PATHS.contacts.home,
//     icon: 'ContactsIcon',
//   },
//   {
//     title: 'Create your networks',
//     description:
//       'Organise your contacts into networks (e.g. premium suppliers, certified suppliers, etc.)',
//     buttonText: 'Networks',
//     link: PATHS.privateGroups.home,
//     icon: 'PadlockIcon',
//   },
//   {
//     title: 'Create and manage your prices',
//     description:
//       'Create or upload your price grids, manage who can see them (private, public vs. contacts only), and see interested suppliers.',
//     buttonText: 'My prices',
//     link: PATHS.prices.home,
//     icon: 'PriceTagIcon',
//   },
//   {
//     title: 'Send and receive contracts',
//     description:
//       'Send booking confirmations to suppliers, and review offers that you have received.',
//     buttonText: 'Contracts',
//     link: PATHS.offers.home,
//     icon: 'PageIcon',
//   },
//   {
//     title: 'Stockbook',
//     description:
//       'Record the important details about your upcoming deliveries and contracts in one place for your entire organisation to view and manage.',
//     buttonText: 'Stockbook',
//     link: PATHS.stockbook.home,
//     icon: 'StockbookIcon',
//   },
// ];

const Wrapper = styled(Stack)`
  max-width: 960px;
`;

const HomePage: NextPage = () => {
  const { data: org } = useBuyerOrg();

  const { isLoading, data: config } = useQuery(['reports'], () =>
    new ApiClient().organisationApi.orgConfigRetrieve({
      buyerOrgId: org?.buyer_org_id || '',
    })
  );

  {
    /** TODO: disable for the moment. As the page is quite busy with the other features. [Mon 20 Jan] */
  }
  // const [stockRequiredRef, setStockRequiredRef] = useState<any>(null);

  // const handleChildRef = (ref: any) => {
  //   setStockRequiredRef(ref);
  // };

  return (
    <Layout meta={{ title: 'Home' }}>
      <PageWrapper fullHeight includeMarginTop>
        <Wrapper space={2}>
          {/*todo hook this up to the config api*/}
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {config?.data.reports.homepage?.find(
                (feature) => feature.id === 'booking_summary'
              ) && <BookingSummaryCard />}

              <>
                {config?.data.reports.homepage?.find(
                  (feature) => feature.id === 'stockbook_summary'
                ) && <StockbookRequiredSummary />}
              </>
            </>
          )}
          {/*todo disable for the moment. As the page is quite busy with the other features*/}
          {/*<FeatureItems auto={{ min: '240px' }} gap={3}>*/}
          {/*  {features.map(({ title, description, link, icon, buttonText }) => (*/}
          {/*    <FeatureItem key={title}>*/}
          {/*      <Stack space={0}>*/}
          {/*        <Text fontWeight='bold' fontSize={3}>*/}
          {/*          {title}*/}
          {/*        </Text>*/}
          {/*        <Text>{description}</Text>*/}
          {/*      </Stack>*/}
          {/*      <Link href={link} passHref>*/}
          {/*        <Button size='small' iconLeft={icon}>*/}
          {/*          {buttonText}*/}
          {/*        </Button>*/}
          {/*      </Link>*/}
          {/*    </FeatureItem>*/}
          {/*  ))}*/}
          {/*</FeatureItems>*/}

          <Stack space={1}>
            <Text fontWeight='bold' fontSize={3}>
              Need help?
            </Text>
            <Text>
              If you have any other questions, please contact us immediately
            </Text>
            <Text>
              <Text fontWeight='bold' as='span'>
                Office:
              </Text>{' '}
              1300 812 345
            </Text>
            <Text>
              <Text fontWeight='bold' as='span'>
                Email:
              </Text>{' '}
              <Text
                as='a'
                color='blue'
                fontWeight='bold'
                href='mailto:support@agoralivestock.com.au'
                target='_blank'
                rel='noopener noreferrer'
              >
                support@agoralivestock.com.au
              </Text>
            </Text>
            <Text>
              <Text fontWeight='bold' as='span'>
                Mobile:
              </Text>{' '}
              0427 279 988
            </Text>
            <Text>Feature requests or feedback are greatly appreciated!</Text>
            <Text>
              Thanks, <br />
              <br /> Agora Livestock
            </Text>
          </Stack>
        </Wrapper>
      </PageWrapper>
    </Layout>
  );
};

export default withAuth(HomePage);
